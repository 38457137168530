import Form from "./form";
import Cookies from 'js-cookie'

$('#popupAuthForm').on('submit', (e) => {

  let form = new Form(e)
  form.post((response) => {
    if (response.status === 'success') {
      let popup = $(e.currentTarget).parent().data('popup')
      if (popup && popup.length > 0)
      {
        Cookies.set('popup_open', popup, {
          expires: 1
        })

        if (popup === 'waiting-list') {
          Cookies.set('waitingListOfferId', $(e.currentTarget).parent().data('offerId'), {
            expires: 1
          })
          Cookies.set('waitingListModelId', $(e.currentTarget).parent().data('modelId'), {
            expires: 1
          })
        }
      }
    }
    window.location.href = window.location.href=form.selector.data('success-url')
  })
})

$('#resetPasswordForm').on('submit', (e) => {
  let form = new Form(e)
  form.post((response) => {
    let informBlock = $('.js-informSuccess')
    let submitBlock = $('.js-resetSubmit')

    informBlock.css('display', 'block')
    submitBlock.css('display', 'none');
  })
})

$('#systemAuthForm').on('submit', (e) => {
  let form = new Form(e)
  form.post((response) => {
    window.location.href = window.location.href=form.selector.data('success-url')
  })
})

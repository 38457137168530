import $ from 'jquery'

export default class WaitingList {
    constructor() {
        this.offerId = 0
        this.modelId = 0

        this.init()
    }

    init () {
        this.bindEvents()
    }

    bindEvents () {
        let _this = this

        $(document).on('submit', '.js-waitingForm', function (e) {
            e.preventDefault()
            let data = $(e.currentTarget).serializeArray()

            data.push({
                name: 'sessid',
                value: window.app.sessid,
            })

            data.push({
                name: 'offerId',
                value: _this.offerId && _this.offerId !== 'undefined' ? _this.offerId : 0,
            })

            data.push({
                name: 'modelId',
                value: _this.modelId && _this.modelId !== 'undefined' ? _this.modelId : 0,
            })

            $.ajax({
                url: $(e.currentTarget).attr('action'),
                method: $(e.currentTarget).attr('method'),
                data: data,
            }).then((response) => {
                if (response.status === 'success') {
                    $('.js-waitingForm .form-field').hide()
                    $('.js-waitingForm .aside-panel__button').hide()
                    $('.js-waitingForm .js-successWaitingAdd').show()
                } else if (response.errors) {

                }
            })
        })
    }
}
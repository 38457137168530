import Swiper from 'swiper'
import {
  Autoplay, Pagination, Navigation, Zoom,
} from 'swiper/modules'

export function initProductSwiper() {
  if ($('.js-productSwiper').length > 0) {
    $('.js-productSwiper').each((_, swiperEl) => {
      const pagination = $(swiperEl).find('.swiper-pagination')
      const parent = $(swiperEl).parent()

      const slideChange = () => {
        const decor = parent.find('.catalog-product-swiper-decor path')
        const fill = $(swiperEl).find('.swiper-slide-active').attr('data-color')

        if (fill) {
          decor.attr('fill', fill)
        }
      }

      new Swiper($(swiperEl)[0], {
        zoom: true,
        modules: [Autoplay, Pagination, Navigation, Zoom],
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        spaceBetween: 10,
        loop: true,
        pagination: {
          el: pagination[0],
          clickable: true,
        },
        on: {
          slideChangeTransitionEnd() {
            slideChange()
          },
        },
      })

      setTimeout(() => {
        slideChange()
      }, 1000)
    })
  }
}

initProductSwiper()

import Mustache from 'mustache'
import CatalogProduct from "./catalogproduct";

export default class CatalogProductMob extends CatalogProduct {

  render() {
    this.$catalogColorSizes = $('.js-detail-page-mob .js-color-sizes')

    this.catalogColorSizesTemplate = $('.js-detail-page-mob #js-color-sizes-template').html()

    this.DOM.$catalogSliderSizes = this.DOM.$el.find('.js-slider-sizes-mob')
    this.catalogSliderSizesTemplate = this.DOM.$el
        .find('#js-slider-sizes-mob-template')
        .html()

    $('.js-svgMoveItem[data-id="' + this.checkedRarity + '"]').trigger('click')
    this.renderColorSizes()
  }

  bindEvents() {
    let _this = this
    $(document).on('click', '.js-svgMoveItem', (e) => {
      this.checkedRarity = $(e.currentTarget).data('id')

      _this.renderColorSizes(true)
    })

    $(document).on('click', '.js-detail-page-mob .js-catalogSizeSlider', function () {
      window.checkedOffer = _this.checkedOffer = _this.DOM.$catalogSliderSizes.find('.swiper-slide-active').data('offer-id')
      _this.renderAddToBasketButton()
    })

    $(document).on('click', '.js-detail-page-mob .catalog-color__item', function (e) {
      _this.checkedColor = $(e.currentTarget).data('item-id')
      $(e.currentTarget)
        .addClass('is_active')
        .siblings()
        .removeClass('is_active')

      $(e.currentTarget).parents('.catalog-mob-accordion').find('.catalog-mob-accordion__title').text(
        $(e.currentTarget).text()
      )

      _this.renderPreviewSlider()
      _this.renderSizesSlider()
      _this.setUrl()
    })

    $(document).on('click', '.js-detail-page-mob .js-add-to-basket', function (e) {
      if (_this.checkedOffer && _this.checkedOffer > 0) {
        _this.sendRequest($(e.currentTarget).data('action'), [
          { name: 'offerId', value: _this.checkedOffer}
        ])
       _this.basketManager.addBasket(_this.checkedOffer)
      }
    })

    $(document).on('click', '.js-waitingListProductMobAdd', function (e) {
      _this.app.waitingList.offerId = _this.checkedOffer
      $('.auth-aside').data('offerId', _this.checkedOffer)
    })
  }

  renderSizesSlider() {
    const $activeColorId = this.data.ITEMS[this.checkedRarity].ITEMS.filter(el => el.ID == this.checkedColor)

    if ($activeColorId[0]){
      const rendered = Mustache.render(this.catalogSliderSizesTemplate, $activeColorId[0])
      this.DOM.$catalogSliderSizes.html(rendered)

      window.updateCatalogSizeSliderSlider()

      window.checkedOffer = this.checkedOffer = this.DOM.$catalogSliderSizes.find('.swiper-slide-active').data('offer-id')
      this.renderAddToBasketButton()
    }
  }

  renderAddToBasketButton() {
    const $catalogAddToBasket = $('.js-detail-page-mob .js-add-to-basket-container')
    const $activeColor = this.data.ITEMS[this.checkedRarity].ITEMS.filter(el => el.ID == this.checkedColor)
    const $activeOffer = $activeColor[0].OFFERS.filter(el => el.ID == this.checkedOffer)

    if ($activeOffer.length > 0)
    {
      const template = $('.js-detail-page-mob #js-add-to-basket-template').html()

      const rendered = Mustache.render(template, $activeOffer[0])
      $catalogAddToBasket.html(rendered)
    }
  }
}

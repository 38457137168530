import Form from './form'

$('#personalForm').on('submit', (e) => {
  let informBlock = $('.js-personalFormInformSuccess')
  let submitBlock = $('.js-personalFormSubmitBlock')

  let form = new Form(e)
  form.post((response) => {
    informBlock.css('display', 'block')
    submitBlock.css('display', 'none')

    setTimeout(() => {
      informBlock.css('display', 'none')
      submitBlock.css('display', 'block')
    }, 5000)
  })
})

function deleteNonLatin(text) {
  return text.replace(/[^A-Za-z0-9]/gi, '')
}

$('.js-inputLatin').on('input change', (e) => {
  const cleanValue = deleteNonLatin(e.target.value)
  e.target.value = cleanValue
})

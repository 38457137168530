import Form from './form'
import Mustache from "mustache";
import Cookies from 'js-cookie'

export default class PhoneAuth {
  constructor() {
    this.checkCodeSelector = $('.js-auth-check-code-body')
    this.checkCodeDOM = this.checkCodeSelector.html()

    this.bindEvents()
  }

  renderFirstStep(data) {
    this.checkCodeSelector.html(Mustache.render(this.checkCodeDOM, data))
  }

  handleFirstStep(event) {
    let form = new Form(event)
    form.post((response) => {
      this.renderFirstStep(response.data)
      window.authFormOpen('login-by-code')
    })
  }

  handleSecondStep(event) {
    let form = new Form(event)
    form.post((response) => {
      if (response.status === 'success') {
        let popup = $(event.currentTarget).parent().data('popup')
        if (popup && popup.length > 0)
        {
          Cookies.set('popup_open', popup, {
            expires: 1
          })

          if (popup === 'waiting-list') {
            Cookies.set('waitingListOfferId', $(e.currentTarget).parent().data('offerId'), {
              expires: 1
            })
            Cookies.set('waitingListModelId', $(e.currentTarget).parent().data('modelId'), {
              expires: 1
            })
          }
        }
      }
      window.location.href = window.location.href=form.selector.data('success-url')
    })
  }

  bindEvents() {
    $('#popupAuthPhoneForm').on('submit', (e) => this.handleFirstStep(e))
    $('#popupAuthCheckCodeForm').on('submit', (e) => this.handleSecondStep(e))
  }
}

$(document).on('click', '.js-authButton', (e) => {
  e.preventDefault()
  $('.auth-aside').addClass('is_opened')
  $('.auth-aside').data('popup', $(e.currentTarget).data('popup') ? $(e.currentTarget).data('popup') : false)

  $('body').addClass('body-lock')
})

$('.js-authAsideClose').on('click', () => {
  $('.auth-aside').removeClass('is_opened')
  $('.auth-aside').data('popup', false)

  $('body').removeClass('body-lock')
})

$('[data-auth-form-url]').on('click', (e) => {
  const currentAuthUrl = $(e.currentTarget).attr('data-auth-form-url')

  showAuthCurrentStep(currentAuthUrl)
})

$('.js-regNextSubmit').on('click', (e) => {
  e.preventDefault()

  if ($('.js-regnickname').val().length > 0) {
    $('.js-nicknameInputHidden').val($('.js-regnickname').val())
    showAuthCurrentStep('reg-by-email')
  } else {
    $('.js-regnickname').valid()
  }

  return false
})

$('.js-loginVartiantButton').on('click', (e) => {
  const variant = $(e.currentTarget).data('variant')

  $(e.currentTarget).addClass('is_active').siblings().removeClass('is_active')
  $(`[data-login-variant="${variant}"]`).show().siblings().hide()
})

const showAuthCurrentStep = (url) => {
  const currentForm = $(`[data-auth-form-step="${url}"]`)

  $('[data-auth-form-step]').hide()
  currentForm.show()

  $('.auth-aside').scrollTop(0)
}

window.authFormOpen = (step) => {
  showAuthCurrentStep(step)
}

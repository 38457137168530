import './preloader'
import Catalog from './catalog'
import './tab'
import './menu'
import './cart-aside'
import './validate'
import './product'
import './footer'
import './header'
import './order-group'
import './order-products'
import CatalogProduct from "./catalogproduct"
import CatalogProductMob from "./catalogproductmob"
import './catalog-filter'
import './catalog-entering/index'
import './home'
import './read-more'
import './SvgMoveAnimation'
import './auth'
import './catalog-size-slider'
import './catalog-mob'
import './scroll'
import './accordion'
import './personal'
import './aside-panel'
import './map/map'
import './order-cart'
import './loader'
import BasketManager from './basketmanager'
import HeaderBasket from './headerbasket'
import Num from './num'
import Checkout from './checkout'
import Autocomplete from './autocomplete'
import RestorePassword from './restore-password';
import PhoneAuth from './auth-by-phone'
import PersonalAppealsForm from './personal-appeals-form'
import Register from './register'
import './personal-form'
import './mask'
import './auth-by-password'
import {BX_META_SESSID_SELECTOR, BX_SESSID_RESPONSE_HEADER} from "./constants";
import WaitingList from "./waitinglist";
import Cookies from 'js-cookie'
import {asidePanelShow} from "./aside-panel";

export default class App {
  constructor() {
    this.DOM = {}
    this.sessid = $(BX_META_SESSID_SELECTOR).attr('content') || ''

    this.init()
  }

  init() {
    this.createComponents()

    if (Cookies.get('popup_open') && Cookies.get('popup_open').length > 0) {
      asidePanelShow(Cookies.get('popup_open'))
      Cookies.remove('popup_open')
    }
  }

  createComponents () {
    this.initBasketManager()
    this.initWaitingList()
    this.initHeaderBasket()
    this.initCatalog()
    this.initCatalogProduct()
    this.initCatalogProductMob()
    this.initCheckout()
    this.initNum()
    this.initRestorePassword()
    this.initPhoneAuth()
    this.initPersonalAppealsForm()
    this.initRegister()
    this.initBase()
  }

  initBasketManager () {
    this.basketManager = new BasketManager(this)
  }

  initWaitingList () {
    this.waitingList = new WaitingList()

    let _this = this
    $(document).on('click', '.js-waitingListModelMobAdd', function (e) {
      _this.waitingList.modelId = $(e.currentTarget).data('modelId')
      $('.auth-aside').data('modelId', $(e.currentTarget).data('modelId'))
    })

    if (Cookies.get('waitingListOfferId') && Cookies.get('waitingListOfferId').length > 0) {
      _this.waitingList.offerId = Cookies.get('waitingListOfferId')
      Cookies.remove('waitingListOfferId')
    }
    if (Cookies.get('waitingListModelId') && Cookies.get('waitingListModelId').length > 0) {
      _this.waitingList.modelId = Cookies.get('waitingListModelId')
      Cookies.remove('waitingListModelId')
    }
  }

  initCatalog() {
    $('.catalog-page').each((idx, el) => new Catalog($(el), this))
  }

  initCatalogProduct() {
    $('.js-detail-page').each((idx, el) => new CatalogProduct($(el), this.basketManager, this))
  }

  initCatalogProductMob() {
    $('.js-detail-page-mob').each((idx, el) => new CatalogProductMob($(el), this.basketManager, this))
  }

  initHeaderBasket() {
    this.headerBasket = null

    $('.js-header-basket').each(
      (idx, el) =>
        (this.headerBasket = new HeaderBasket($(el), this.basketManager, this))
    )
  }

  initCheckout() {
    $('.js-checkout').each(
      (idx, el) => (this.order = new Checkout($(el), this.basketManager, this))
    )
  }

  initNum () {
    $('.num').each((idx, el) => new Num($(el)))
  }

  initRestorePassword() {
    this.restorePassword = new RestorePassword()
  }

  initPhoneAuth() {
    this.phoneAuth = new PhoneAuth()
  }

  initPersonalAppealsForm() {
    this.personalAppealsForm = new PersonalAppealsForm()
  }

  initRegister() {
    this.personalAppealsForm = new Register()
  }

  static updateSessid(sessid) {
    $(BX_META_SESSID_SELECTOR).attr('content', sessid)
  }

  initBase() {
    $('.js-payment').on('click', (e) => {
      const currentTarget = $(e.currentTarget)
      currentTarget
          .parents('.personal-archive')
          .find('.js-payment-form form')
          .submit()
    })

    $('.catalog-mob-accordion__header.js-accordionToggle').trigger('click')
  }
}

import { SvgMoveAnimation } from '../SvgMoveAnimation'

// Анимация стилей на мобилке
if ($('.js-svgMoveStyleMob').length > 0) {
  $('.js-svgMoveStyleMob').each((_, el) => {
    new SvgMoveAnimation({
      rootEl: $(el),
      configPoints: {
        0: [75, 80.2, 84.5, 165.5, 169.5],
        1: [69.5, 75, 80.2, 84.5, 165.5],
        2: [65.5, 69.5, 75, 80.2, 84.5],
        3: [184.5, 165.5, 169.5, 175, 180.2],
        4: [180.2, 184.5, 165.5, 169.5, 175],
      },
    })
  })
}

/* eslint-disable operator-linebreak */
export class SvgMoveAnimation {
  constructor(options) {
    this.configPoints = options.configPoints
    this.animateTimeout = 2500
    this.resetCircleTimeout = 2500
    this.disabled = false
    this.$rootEl = options.rootEl
    this.$svgMoveItem = this.$rootEl.find('.js-svgMoveItem')
    this.$svgCircles = $(`.${this.$rootEl.data('root-circles')}`)
    this.$prevButton = $('.js-catalogRareMobPrev')
    this.$nextButton = $('.js-catalogRareMobNext')

    this.initHandlers()
    this.checkNavigationButtons()
  }

  initHandlers() {
    this.$svgMoveItem.on('click', (e) => this.pointClick(e))

    this.$prevButton.on('click', () => {
      if (this.disabled) {
        return
      }

      this.$rootEl.find('.js-svgMoveItem').eq(3).trigger('click')

      this.disabled = true

      setTimeout(() => {
        this.disabled = false
      }, this.resetCircleTimeout)
    })

    this.$nextButton.on('click', () => {
      if (this.disabled) {
        return
      }

      this.$rootEl.find('.js-svgMoveItem').eq(1).trigger('click')

      this.disabled = true

      setTimeout(() => {
        this.disabled = false
      }, this.resetCircleTimeout)
    })
  }

  pointClick(e) {
    const currentIndex = $(e.currentTarget).index()
    const currentShift = this.configPoints[currentIndex]

    console.log('currentShift', currentShift)

    if (currentShift) {
      $('[data-id]', this.$svgCircles).each((index, el) => {
        if (!currentShift[index]) {
          return
        }

        const transition =
          currentIndex < 2
            ? `${2000 - index * 150}ms`
            : `${2000 + index * 150}ms`

        $(el).css({
          transition,
          'offset-distance': `${currentShift[index]}%`,
        })
      })

      $('.catalog-point').each((index, el) => {
        if (!currentShift[index]) {
          return
        }

        const transition =
          currentIndex < 2
            ? `${2000 - index * 150}ms`
            : `${2000 + index * 150}ms`

        $(el).css({
          transition,
          'offset-distance': `${currentShift[index]?.distance}%`,
          r: currentShift[index]?.r,
        })
      })
    }

    $('[data-id]', this.$svgCircles)
      .eq(currentIndex)
      .addClass('is_active')
      .siblings()
      .removeClass('is_active')

    this.$svgMoveItem.addClass('is_disabled')

    setTimeout(() => {
      this.moveItems(currentIndex)

      this.checkNavigationButtons()

      this.$svgMoveItem.removeClass('is_disabled')
    }, this.animateTimeout - 1000)

    setTimeout(() => {
      this.resetCirclePoints()
      this.$svgMoveItem.on('click', (ev) => this.pointClick(ev))
    }, this.resetCircleTimeout)
  }

  resetCirclePoints() {
    const $fields = $('[data-id]', this.$svgCircles)
    $fields.each((_, el) => {
      const distance = $(el).css('offset-distance').replace('%', '')
      const currentDistance = distance > 105 ? distance - 100 : distance

      $(el)
        .css('transition', 'none')
        .css('offset-distance', `${currentDistance}%`)
    })
    $fields
      .sort((prev, next) => {
        const an = +$(prev).css('offset-distance').replace('%', '')
        const bn = +$(next).css('offset-distance').replace('%', '')

        if (an > bn) {
          return 1
        }
        if (an < bn) {
          return -1
        }
        return 0
      })
      .appendTo($fields.parent())
  }

  moveItems(currentIndex) {
    const copied = [...this.$rootEl.find('.js-svgMoveItem')]

    const item = this.$rootEl.find('.js-svgMoveItem')
    const parent = item.parent()

    const shift = {
      0: 2,
      1: 1,
      2: 0,
      3: 4,
      4: 3,
    }

    parent.html(
      copied.concat(copied.splice(0, copied.length - shift[currentIndex])),
    )
  }

  checkNavigationButtons() {
    if (this.$rootEl.find('.js-svgMoveItem').eq(1).hasClass('is_hidden')) {
      this.$nextButton.addClass('is_disabled')
    } else {
      this.$nextButton.removeClass('is_disabled')
    }

    if (this.$rootEl.find('.js-svgMoveItem').eq(3).hasClass('is_hidden')) {
      this.$prevButton.addClass('is_disabled')
    } else {
      this.$prevButton.removeClass('is_disabled')
    }
  }
}
